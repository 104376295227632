import React from "react"
import parse from "html-react-parser"

import Layout from "../layouts/base"
import SEO from "../components/seo"

const NotFoundPage = () => {
	const siteTitle = '404: Not Found'

  return (
    <Layout>
      <SEO title={siteTitle} />

			<main className="container">
				<h1>{siteTitle}</h1>
				<p>{parse(`You just hit a route that doesn't exist... the sadness.`)}</p>
			</main>
    </Layout>
  )
}

export default NotFoundPage
